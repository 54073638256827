var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';
// components
import { fileData } from '../../file-thumbnail';
// utils
import { fData } from '../../../utils/formatNumber';
export default function RejectionFiles(_a) {
    var fileRejections = _a.fileRejections;
    if (!fileRejections.length) {
        return null;
    }
    return (_jsx(Paper, __assign({ variant: "outlined", sx: {
            py: 1,
            px: 2,
            mt: 3,
            bgcolor: function (theme) { return alpha(theme.palette.error.main, 0.08); },
            borderColor: function (theme) { return alpha(theme.palette.error.main, 0.24); },
        } }, { children: fileRejections.map(function (_a) {
            var file = _a.file, errors = _a.errors;
            var _b = fileData(file), path = _b.path, size = _b.size;
            return (_jsxs(Box, __assign({ sx: { my: 1 } }, { children: [_jsxs(Typography, __assign({ variant: "subtitle2", noWrap: true }, { children: [path, " - ", size ? fData(size) : ''] })), errors.map(function (error) { return (_jsxs(Box, __assign({ component: "span", sx: { typography: 'caption' } }, { children: ["- ", error.message] }), error.code)); })] }), path));
        }) })));
}
